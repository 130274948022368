<template>
  <div v-if="listData && listData.length > 0">
    <div class="box-div" v-for="(item, index) in listData" :key="index">
      <template v-if="type === 'radio'">
        <div style="display: flex;align-items: center;">
          <div class="title">
            <span v-html="item.title"></span>
          </div>
          <van-popover
            v-if="item.tips"
            v-model="item.tipsStatus"
            theme="dark"
            trigger="click"
            :placement="item.tipsWeiZhi ? item.tipsWeiZhi : 'right-start'"
            style="margin-left: 5px;"
          >
            <div
              v-html="item.tips"
              style="max-width: 100px;padding: 15px"
            ></div>
            <template #reference>
              <van-icon name="info" color="#A3A3A3" />
            </template>
          </van-popover>
        </div>
        <div class="box-div-dis">
          <div
            class="box-div-dis-zi"
            @click="select(item.title, zi, item.fenzhi, index2)"
            v-for="(zi, index2) in item.columns"
            :key="index2"
          >
            <span style="margin-top: 2px">
              <svg
                v-if="compD(item.title, zi)"
                t="1718613548086"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2728"
                width="18"
                height="18"
              >
                <path
                  d="M512 985C250.77 985 39 773.23 39 512S250.77 39 512 39s473 211.77 473 473-211.77 473-473 473z"
                  fill="#2F54EB"
                  p-id="2729"
                ></path>
                <path
                  d="M391.84 648.34l321.887-321.635c15.627-15.615 40.953-15.605 56.568 0.022 15.615 15.627 15.605 40.953-0.022 56.568L419.957 733.338c-15.679 15.666-41.108 15.597-56.7-0.156L201.571 569.845c-15.541-15.7-15.412-41.027 0.288-56.568 15.7-15.541 41.026-15.413 56.568 0.288L391.84 648.34z"
                  fill="#FFFFFF"
                  p-id="2730"
                ></path>
              </svg>
              <svg
                v-else
                t="1718613083329"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1582"
                width="18"
                height="18"
              >
                <path
                  d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m0-34.133333c263.918933 0 477.866667-213.947733 477.866667-477.866667S775.918933 34.133333 512 34.133333 34.133333 248.081067 34.133333 512s213.947733 477.866667 477.866667 477.866667z"
                  fill="#999999"
                  p-id="1583"
                ></path>
              </svg>
            </span>
            <!-- <span style="margin-left: 8px;word-break:break-all">{{zi}}</span> -->
            <span
              v-html="zi"
              style="margin-left: 8px;word-break:break-all"
            ></span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateTreeList",
  props: {
    listData: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    selectValue: {
      type: [Array],
      required: true,
    },
  },
  watch: {
    selectValue: {
      immediate: true, // 立即执行一次
      handler(newValue) {
        this.list = [...newValue];
        if (this.list.length > 0 && this.fenzhi.length === 0) {
          let fenzhiLi = [];
          for (let i = 0; i < this.list.length; i++) {
            const data = this.listData.filter(
              (item) => item.title === this.list[i].title
            )[0];
            const dataP = {
              title: data.title,
              value: [],
            };
            for (let j = 0; j < data.columns.length; j++) {
              for (let k = 0; k < this.list[i].value.length; k++) {
                if (data.columns[j] === this.list[i].value[k]) {
                  dataP.value.push(data.fenzhi[j]);
                }
              }
            }
            fenzhiLi.push(dataP);
          }
          this.fenzhi = fenzhiLi;
        }
      },
    },
  },
  data() {
    return {
      list: [],
      showPopover: false,
      fenzhi: [],
    }; //dataReturm
  },
  methods: {
    compD(title, zi) {
      const list = this.list.filter((item) => {
        return item.title === title;
      });
      if (list && list.length > 0) {
        return list[0].value.some((item) => item === zi);
      }
    },
    select(title, value, fenzhi, fIndex) {
      if (this.type === "radio") {
        const index = this.list.findIndex((item) => item.title === title);
        if (index === -1) {
          this.list.push({ title, value: [value] });
          const indexFz = this.fenzhi.findIndex((item) => item.title === title);
          if (fenzhi) {
            if (indexFz === -1) {
              this.fenzhi.push({ title, value: [fenzhi[fIndex]] });
            } else {
              this.fenzhi[indexFz] = { title, value: [fenzhi[fIndex]] };
            }
          }
        } else {
          const index2 = this.list[index].value.findIndex(
            (item) => item === value
          );
          if (index2 == -1) {
            this.$set(this.list, index, { title, value: [value] });
            const indexFz = this.fenzhi.findIndex(
              (item) => item.title === title
            );
            if (fenzhi) {
              if (indexFz === -1) {
                this.fenzhi.push({ title, value: [fenzhi[fIndex]] });
              } else {
                this.fenzhi[indexFz] = { title, value: [fenzhi[fIndex]] };
              }
            }
          } else {
            this.$set(this.list, index, { title, value: [] });
            this.$set(this.fenzhi, index, { title, value: [] });
          }
        }
        // const indexFz = this.fenzhi.findIndex((item) => item.title === title)
        // if (fenzhi) {
        //   if (indexFz === -1) {
        //     this.fenzhi.push({title, value: [fenzhi[fIndex]]})
        //   } else {
        //     this.fenzhi[indexFz] = {title, value: [fenzhi[fIndex]]}
        //   }
        // }

        // console.log(this.fenzhi)
        let fenzhiResult = [];
        for (let i = 0; i < this.fenzhi.length; i++) {
          for (let j = 0; j < this.fenzhi[i].value.length; j++) {
            fenzhiResult.push(this.fenzhi[i].value[j]);
            console.log("4444", fenzhiResult);
          }
        }
        this.$emit("change", this.list, fenzhiResult);
      }
    },
  },
};
</script>

<style scoped>
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.title {
}
.box-div-dis {
  display: flex;
  color: #777777;
  flex-wrap: wrap;
  margin-top: 5px;
}
.box-div-dis-zi {
  display: flex;
  margin: 7px 0;
  min-width: 50%;
}
</style>
