<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient
            :memberId="memberId"
            :selectValue="selectValue"
            @change="userChange"
          />
          <TemplateFiveList
            :type="'radio'"
            :list-data="listData"
            :selectValue="listSelect"
            @change="listChange"
          ></TemplateFiveList>
          <TemplateFiveListOneRadio
            :type="'radio'"
            :list-data="listData1"
            :selectValue="listSelectRadio"
            @change="listChangeCheck"
          ></TemplateFiveListOneRadio>
          <!-- <div class="question">
                <p>PEEP评分（机械通气时） </p>
                <van-field name="radio" >
                  <template #input>
                   <van-radio-group v-model="check1" direction="horizontal"  @change="changeFn">                
                     <van-radio name="≤5cmH₂O"  @click="clickFn">≤5cmH₂O</van-radio>
                     <van-radio name="6~8cmH₂O" @click="clickFn">6~8cmH₂O</van-radio>
                     <van-radio name="9~11cmH₂O"  @click="clickFn">9~11cmH₂O</van-radio>
                     <van-radio name="12~14cmH₂O"  @click="clickFn">12~14cmH₂O</van-radio>
                     <van-radio name="≥15cmH₂O"  @click="clickFn">≥15cmH₂O</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div> -->
          <div
            v-if="compute === true"
            style="margin: 16px;display: flex;justify-content: center;"
          >
            <van-button
              class="formula-template-button"
              block
              type="info"
              @click="submit"
              style="width:90px"
              >提交</van-button
            >
          </div>
          <!--          &lt;!&ndash; 计算结果 &ndash;&gt;-->
          <!--          <div class="CalculationResultsBox" v-if="countText || countText !== ''">-->
          <!--            <div class="CalculationResults"><span>计算结果</span></div>-->
          <!--            &lt;!&ndash; 具体标准 &ndash;&gt;-->
          <!--            <div>-->
          <!--              <div class="CalculationDetail"><span></span>{{result}}分 {{countText}}</div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;            <div>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <div class="CalculationDetail"><span></span>具体标准：</div>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
          <!--          </div>-->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults
        v-if="show"
        :countText="countTextT"
        :countTextList="[]"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="应激指数"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import TemplateFiveListOneRadio from "@/components/Template5/TemplateFiveListOneRadio.vue";
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import CalculationResults from "@/components/CalculationResults";

export default {
  name: "medicalSearch",
  data() {
    return {
      compute: true,
      countTextT: "",
      listSelect: [],
      check1: "",
      checkRadioFlag: false,
      listData: [
        {
          title: "胸部X线评分",
          columns: [
            "无肺泡实变",
            "肺泡实变局限于1象限",
            "肺泡实变局限于2象限",
            "肺泡实变局限于3象限",
            "肺泡实变局限于4象限",
          ],
          fenzhi: [0, 1, 2, 3, 4], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: "低氧血症评分：PaO₂/FiO₂",
          columns: [
            "≥300mmHg",
            "225~299mmHg",
            "175~224mmHg",
            "100~174mmHg",
            "<100mmHg",
          ],
          fenzhi: [0, 1, 2, 3, 4], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        // {
        //   title: "PEEP评分（机械通气时）",
        //   columns: [
        //     "≤5cmH₂O",
        //     "6~8cmH₂O ",
        //     "9~11cmH₂O",
        //     "12~14cmH₂O",
        //     "≥15cmH₂O",
        //   ],
        //   fenzhi: [0, 1, 2, 3, 4], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        // },
        // {
        //   title: "顺应性评分（可获得时）",
        //   columns: [
        //     "≥80ml/cmH₂O",
        //     "60~79ml/cmH₂O",
        //     "40~59ml/cmH₂O",
        //     "20~39ml/cmH₂O",
        //     "≤19ml/cmH₂O",
        //   ],
        //   fenzhi: [0, 1, 2, 3, 4], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        // },
      ],
      listData1: [
        {
          title: "PEEP评分（机械通气时）",
          columns: [
            "≤5cmH₂O",
            "6~8cmH₂O ",
            "9~11cmH₂O",
            "12~14cmH₂O",
            "≥15cmH₂O",
          ],
          fenzhi: [0, 1, 2, 3, 4], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: "顺应性评分（可获得时）",
          columns: [
            "≥80ml/cmH₂O",
            "60~79ml/cmH₂O",
            "40~59ml/cmH₂O",
            "20~39ml/cmH₂O",
            "≤19ml/cmH₂O",
          ],
          fenzhi: [0, 1, 2, 3, 4], //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ["正常或轻伤", "中度创伤", "重度创伤"],
      nianling: "",
      xingbie: null,
      tableData: {},
      countText: "",
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
      fenzhiList: [],
      show: false,
      listSelectRadio: [],
      fenzhiListRadio: [],
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateFiveList,
    CalculationResults,
    TemplateFiveListOneRadio,
  },
  async created() {
    await this.test();
    await this.getTableData();
  },

  methods: {
    // clickFn() {
    //   if(this.checkRadioFlag) {
    //     this.check1 = '';
    //   }
    //   this.checkRadioFlag = true;
    // },
    //  changeFn() {
    //   this.checkRadioFlag = false;
    //  },
    listChangeCheck(result, fenzhi) {
      this.listSelectRadio = result; //结果数组
      this.fenzhiListRadio = fenzhi; //分值数组
      this.show = false;
      console.log(this.listSelectRadio);
    },
    listChange(result, fenzhi) {
      this.listSelect = result; //结果数组
      this.fenzhiList = fenzhi; //分值数组
      this.show = false;
    },
    coumputTetxt() {
      let fenshu = 0;
      this.listSelect.forEach((value) => {
        const data = this.listData.find((d) => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
      });
      function calculateScore(data, select) {
        select.value.forEach((item) => {
          const index = data.columns.indexOf(item);
          if (index !== -1) {
            fenshu += data.fenzhi[index];
          }
        });
      }
      let fenshu1 = 0;
      if (this.fenzhiListRadio.length !== 0) {
        this.fenzhiListRadio.forEach((item,) => {
          fenshu1 += item;
        
        });
      }
     

      this.result = Math.floor(((fenshu+fenshu1) / (this.listSelect.length+this.fenzhiListRadio.length)) * 100) / 100
      console.log(this.result);

      if (this.result == 0) {
        this.countText = `无肺损伤`;
      } else if (0.1 <= this.result && this.result <= 2.5) {
        this.countText = `轻至中度肺损伤`;
      } else {
        this.countText = `重度肺损伤`;
      }
      console.log(this.countText);

      this.countTextT = `${this.result}分 ${this.countText}`;
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    // 回显
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "WBGDMOFX",
      };
      const res = await getFormula(data.channel, data.code);

      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);

        if (res.code === 0) {
          this.listSelect = res.data.content.data.listSelect;
          this.listSelectRadio=res.data.content.data.listSelectRadio
          if (res.data.patientId)
            this.selectValue = {
              id: res.data.patientId,
              name: res.data.patientName,
            };
          this.result = res.data.result.value;
          this.countText = res.data.result.result;
          this.countTextT = `${res.data.result.value}分 ${res.data.result.result}`;
          //   this.result = res.data.result.value;
          //   this.countText = res.data.result.result
          // this.countValue = `${res.data.result.value}分 ${res.data.result.result}`
          this.show = true;
        }
      }
    },
    async submit() {
      console.log(this.listSelect);
      if (this.listSelect.length == 0) {
        Toast.fail("请完善选择!");
        return;
      }
      const flag = this.listSelect.find((item) => item.title == "胸部X线评分");
      if (flag == undefined) {
        Toast.fail("请完善选项1!");
        return;
      }
      const flag1 = this.listSelect.find(
        (item) => item.title == "低氧血症评分：PaO₂/FiO₂"
      );
      if (flag1 == undefined) {
        Toast.fail("请完善选项2!");
        return;
      }

      this.coumputTetxt();
      const data = {
        columnCode: "0",
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,

        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: {listSelect:this.listSelect,listSelectRadio:this.listSelectRadio},
          
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults: this.countTextT,
        },
      };
      this.show = true;
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return;
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.yingjizhishu = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    async onSubmit() {
      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: { value: this.shengao, unit: "cm" },
          tizhong: { value: this.tizhong, unit: "kg" },
          nianling: { value: this.nianling, unit: "岁" },
          xingbie: { value: this.xingbie == 1 ? "男" : "女" },
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {},
      };
      if (this.xingbie == 1) {
        data.result.nanxing = { value: this.result, unit: "kcal" };
      } else {
        data.result.nvxing = { value: this.result, unit: "kcal" };
      }
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    userChange(data) {
      if (data.birthday && data.birthday !== "") {
        this.nianling = this.calculateAge(data.birthday);
      } else {
        this.nianling = "";
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.Abutton {
}
.question {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}
</style>
